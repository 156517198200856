<template>
  <button
    class="delete-btn btn-has-icon btn"
    :style="{
      color: color,
      textDecoration: textDecoration,
      gap: gap,
    }"
  >
    <svg
      class="delete-icon"
      :style="{
        width: width,
      }"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.96704 4H4.30037H14.967"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.30029 3.99992V2.66659C6.30029 2.31296 6.44077 1.97382 6.69082 1.72378C6.94087 1.47373 7.28 1.33325 7.63363 1.33325H10.3003C10.6539 1.33325 10.9931 1.47373 11.2431 1.72378C11.4932 1.97382 11.6336 2.31296 11.6336 2.66659V3.99992M13.6336 3.99992V13.3333C13.6336 13.6869 13.4932 14.026 13.2431 14.2761C12.9931 14.5261 12.6539 14.6666 12.3003 14.6666H5.63363C5.28 14.6666 4.94087 14.5261 4.69082 14.2761C4.44077 14.026 4.30029 13.6869 4.30029 13.3333V3.99992H13.6336Z"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.63379 7.33325V11.3333"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.3003 7.33325V11.3333"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <span v-if="text">{{ text }}</span>
  </button>
</template>

<script>
export default {
  name: 'DeleteBtn',
  props: {
    width: {
      type: String,
      default: '1.23em',
    },
    text: {
      type: String,
      default: '',
    },
    textDecoration: {
      type: String,
      default: 'underline',
    },
    color: {
      type: String,
      default: 'white',
    },
    gap: {
      type: String,
      default: '0.6em',
    },
  },
};
</script>

<style scoped>
.delete-btn {
  font-size: 1em;
  letter-spacing: 0;
}
.delete-icon {
  margin-top: -0.225em;
}
</style>
