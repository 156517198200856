import { ActionContext } from 'vuex';
import { findById, patchArray, setArray, setItem } from './util';
import { State } from '.';
import { Role } from '@/models/Role';
import api from '../api';

export interface RolesState {
  items: Array<Role>;
}

type Context = ActionContext<RolesState, State>;


export default {
  namespaced: true,
  state: () => ({
    items: Array<Role>()
  }),
  mutations: {
    setRoles: function(state: RolesState, Roles: Array<Role>) {
      state.items = setArray(state.items,Roles);
    },
    patchRoles: function(state: RolesState, Roles: Array<Role>) {
      patchArray(state.items,Roles);
    },
    setRole: function(state: RolesState, Role: Role) {
      setItem(state.items, Role);
    }
  },
  actions: {
    getRoles: async function(context: Context): Promise<Array<Role>> {
      const Roles = await api.getRoles(context.rootState.auth.jwt);
      context.commit('setRoles', Roles);
      return context.state.items;
    },
    getRole: async function(context: Context, id: number): Promise<Role> {
      const Role = await api.getRole(id, context.rootState.auth.jwt);
      context.commit('setRole', Role);
      return findById<Role>(context.state.items, Role.id) as Role;
    },
    addRole: async function(context: Context, Role: Role): Promise<Role> {
      Role = await api.addRole(Role, context.rootState.auth.jwt);
      context.commit('setRole', Role);
      return findById<Role>(context.state.items, Role.id) as Role;
    },
    updateRole: async function(context: Context, Role: Role): Promise<Role> {
      Role = await api.updateRole(Role, context.rootState.auth.jwt);
      context.commit('setRole', Role);
      return findById<Role>(context.state.items, Role.id) as Role;
    },
    deleteRole: async function(context: Context, Role: Role): Promise<void> {
      context.commit('setRoles', context.state.items.filter((i: Role) => i !== Role));
      await api.deleteRole(Role, context.rootState.auth.jwt);
    },
  }
}
