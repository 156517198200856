
    import { useStore } from 'vuex';
    import { State } from '../store';
    import { defineComponent } from 'vue';
    //import { StripeCheckout } from '@vue-stripe/vue-stripe';

    
    export default defineComponent({
        setup() {
            const store = useStore<State>();
            const lineItems = [
                {
                    price: '1',
                    quantity: 1,
                },
                ];

            return { store, lineItems }
        },
        data () {
            return {
              loading: false,
              successURL: 'your-success-url',
              cancelURL: 'your-cancel-url',
            };
        },
        methods: {
            buyonecredit() {
                //(this.$refs['checkoutoneRef'] as StripeCheckout).redirectToCheckout();
            },
            buyfivecredit() {
                //this.$refs.checkoutfiveRef.redirectToCheckout();
            },
            buytencredit() {
                //this.$refs.checkouttenRef.redirectToCheckout();
            }
        }
    });
