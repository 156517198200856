import { ApiResponse } from '@/models/ApiResponse';
import { ActionContext } from 'vuex';
import { AuthenticationData } from '@/models/AuthenticationData'
import { AppleSignInAuthResponse } from '@/models/AppleSignInAuthResponse'
import { LoginResponse } from '@/models/LoginResponse'
import { ProfileResponse } from '@/models/ProfileResponse'
import { User } from '@/models/User'
import { State } from '.';
import api from '@/api'
import { ForgotPasswordData } from '../models/ForgotPasswordData';

export interface AuthState {
  jwt: string;
  user: User | null;
}
type Context = ActionContext<AuthState, State>;

export default {
  state: {
    jwt: '',
    user: null
  },
  mutations: {
    setJwt: function(state: AuthState, jwt: string) {
      state.jwt = jwt
      try {
        if (jwt) {
          localStorage.setItem('jwt', jwt);
        } else {
          localStorage.removeItem('jwt');
        }
      } catch {
        console.log('Local storage is not available.')
      }
    },
    setUser: function(state: AuthState, user: User) {
      state.user = user
    }
  },
  actions: {
    init: async function(context: Context) {
      const jwt = localStorage.getItem('jwt')
      if (jwt) {
        context.commit('setJwt', jwt);
        await context.dispatch('profile');
      }
    },
  
      login: async function (context: Context, data: AuthenticationData): Promise<ApiResponse<LoginResponse>> {
          const response = await api.login(data);
          if (response.isError === false) {
              context.commit('setJwt', response.result.jwt);
              await context.dispatch('profile');
          }
          return response;
      },

      apple: async function (context: Context, data: AppleSignInAuthResponse): Promise<ApiResponse<LoginResponse>> {
          const response = await api.apple(data);
          if (response.isError === false) {
              context.commit('setJwt', response.result.jwt);
              await context.dispatch('profile');
          }
          return response;
      },
      forgotpassword: async function (context: Context, data: ForgotPasswordData): Promise<string> {
          const response = await api.forgotpassword(data);
          return response;
    },
    profile: async function (context: Context): Promise<User | null> {
      try {
        const user = await api.profile(context.state.jwt);
        context.commit('setUser', user);
      return user;
      } catch {
        return null;
      }
    },
    updateProfile: async function (context: Context, user: User): Promise<ProfileResponse> {
      const response  = await api.updateProfile(user, context.state.jwt);
      if (response.success)
        context.commit('setUser', user);
      return response;
    }
  },
}