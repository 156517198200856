
import { useRoute } from 'vue-router'
import api from '../api'
import { useStore } from 'vuex';
import { State } from '../store';
import { JobActivityRecord } from '../models/JobActivityRecord';
import { ref } from 'vue';

export default {
  name: 'JobActivityLog',
  setup() {
    const route = useRoute();
    const store = useStore<State>();
    const records = ref(Array<JobActivityRecord>());

    api.getJobActivityRecords(store.state.auth.jwt, parseInt(route.params.jobId as string)).then(data => records.value = data);
    return { records }
  }
}
