<template>
  <div
    class="r-u-sure-modal projects-users__modal projects-users__modal-middle"
    v-if="startRUSureModal"
    @click.self="closeRUSureModal"
  >
    <div class="r-u-sure-modal-content projects-users__modal-middle-content">
      <CloseBtn @click="closeRUSureModal" />
      <h2>Are you sure you want to remove {{ headingText }}?</h2>
      <p class="r-u-sure-p">
        This action cannot be undone. The tag will be removed from all items.
      </p>
      <DeleteBtn
        class="delete-btn"
        text="Yes, remove"
        textDecoration="none"
        gap="0.75em"
        @click="$emit('confirm')"
      />
      <button class="cancel-btn btn" @click="closeRUSureModal">
        No, cancel
      </button>
    </div>
  </div>
</template>

<script>
import CloseBtn from '../components/CloseBtn';
import DeleteBtn from '../components/DeleteBtn.vue';

export default {
  name: 'RUSureModal',
  components: {
    CloseBtn,
    DeleteBtn,
  },
  props: {
    startRUSureModal: {
      type: Boolean,
      default: false,
    },
    headingText: {
      type: String,
      default: 'to delete ‘My tag’',
    },
    deleteText: {
      type: String,
      default: 'delete',
    },
  },
  methods: {
    deleteTarget: function () {
      this.closeRUSureModal();
    },
    closeRUSureModal: function () {
      this.$emit('existRUSureModal', false);
    },
  },
};
</script>

<style scoped>
.r-u-sure-modal-content {
  width: 36.86em;
  text-align: center;
  padding: 4.3em;
}
.r-u-sure-p {
  font-family: var(--maison-mono);
  letter-spacing: 0;
  line-height: 1.58;
  color: #ffffffb2;
  margin-top: 1.72em;
}
.delete-btn {
  background-color: var(--tomato);
  padding: 0.74em 6.04em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3.58em;
}
.cancel-btn {
  color: var(--white);
  margin-top: 1.175em;
  letter-spacing: 0;
}
</style>
