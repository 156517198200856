
import { useStore } from 'vuex';
import { State } from '../store';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
export default defineComponent({
    setup() {
        const store = useStore<State>();
        return { store }
    },
    methods: {
      logout() {
          const router = useRouter();
          localStorage.removeItem('jwt');
          this.store.commit('setUser', null);
          this.store.commit('setJwt', null);
          window.location.reload();
      }
    }

})
