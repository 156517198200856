<template>
  <button
    :class="[
      position === 'absolute'
        ? 'close-btn btn absolute-close-btn'
        : 'close-btn btn static-xs-close-btn',
    ]"
  >
    <svg
      :class="[
        size === 'l'
          ? 'close-l-icon'
          : size === 's'
          ? 'close-s-icon'
          : 'close-xs-icon',
      ]"
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.25 6.75L6.75 19.25'
        stroke='white'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M6.75 6.75L19.25 19.25'
        stroke='white'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  </button>
</template>

<script>
export default {
  name: 'CloseBtn',
  props: {
    size: {
      type: String,
      default: 'l',
    },
    position: {
      type: String,
      default: 'absolute',
    },
  },
};
</script>

<style>
.close-btn {
  display: flex;
  align-items: center;
}
.btn.absolute-close-btn {
  position: absolute;
  right: 1.23vw;
}
.close-l-icon {
  width: 2.05em;
}
.close-s-icon {
  width: 1.5em;
}
.close-xs-icon {
  width: 1.2em;
}
</style>
