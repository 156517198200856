<template>
  <div class="user-notification__wrapper" v-if="StartUserAddedNotification">
    <div class="user-added-notification user-notification">
      <CloseBtn
        class="close-user-notication"
        size="s"
        @click="changeUserAddedNotificationState"
      />
      <svg
        class="added-icon"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="10" cy="10.5" r="10" fill="white" />
        <path
          d="M13.3332 8.5L8.74984 13.0833L6.6665 11"
          stroke="#06D6B0"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <p>New user has been added</p>
    </div>
  </div>
</template>

<script>
import CloseBtn from '../components/CloseBtn';
export default {
  name: 'UserAddedNotification',
  components: {
    CloseBtn,
  },
  props: {
    StartUserAddedNotification: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    CloseUserAddedNotification: function () {
      this.$emit('changeUserAddedNotificationState', false);
    },
  },
};
</script>

<style scoped>
.user-added-notification {
  background-color: var(--green2);
  padding-right: 2.47em;
}
.added-icon {
  width: 1.3em;
  gap: 0.78em;
}
</style>
