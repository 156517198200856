import { defineComponent, ref, watchEffect } from 'vue';
    import { useForm, useField } from 'vee-validate';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { State } from '../store';
    import { Project } from '../models/Project';
    import { ProjectUpload } from '../models/ProjectUpload';
    import { ProjectType } from '../models/ProjectType';
    import { FeatureSensitivity } from '../models/FeatureSensitivity';
    import { InitProjectUploadRequestData } from '../models/InitProjectUploadRequestData';
    import { PartETag } from '../models/PartETag';
    import { CaptureMode } from '../models/CaptureMode';
    

    import OpenArrow from '../components/OpenArrow.vue';


    import axios from 'axios';
    const showDashboard = ref(false);



    export default defineComponent({
        components: {
            //Dashboard,
            OpenArrow,
        },
        setup() {
            const simpleSchema = {
                name(value: string): boolean | string {
                    if (value && value.trim()) {
                        return true;
                    }
                    return 'Name is required';
                },
                projecttype(value: string | undefined): boolean | string {
                    if (value && value.trim()) {
                        return true;
                    }
                    return 'Type is required';
                },
                projectheight(value: number): boolean | string {
                    if (value && value > 0 && value < 999) {
                        return true;
                    }
                    return 'Height should be more then 0.';
                },
                detail(value: number): boolean | string {
                    if (value && value >= 0) {
                        return true;
                    }
                    return 'Detail is required';
                },
                featureSensitivity(value: number): boolean | string {
                    if (value && value >= 0) {
                        return true;
                    }
                    return 'Feature sensitivity is required';
                },
            };

            const { handleSubmit } = useForm({ validationSchema: simpleSchema });
            const { value: name, errorMessage: nameError } = useField('name');
            const { value: projecttype, errorMessage: projecttypeError } =
                useField('projecttype');
            const { value: projectheight, errorMessage: projectheightError } =
                useField('projectheight');
            const { value: detail, errorMessage: detailError } = useField('detail');
            const { value: featureSensitivity, errorMessage: featureSensitivityError } =
                useField('featureSensitivity');
            const { value: useObjectMask, errorMessage: useObjectMaskError } =
                useField('useObjectMask');
            const { value: projectfile, errorMessage: projectfileError } =
                useField('projectfile');
            const store = useStore<State>();
            const errorMsg = ref('');
            const router = useRouter();
            const submitting = ref(false);
            const basicdetail = ref(true);
            const uploaddetail = ref(false);
            const projectType = ProjectType;
            const images = ref<File[]>([]);
            let project: Project;
            let projectUpload: ProjectUpload;
            const showModal = ref(false);


            const selectedFile = ref<File | null>(null);
            const selectedFileName = ref('');
            const partSize = ref(52428800); // 50 MB
            const partETags = ref<PartETag[]>([]);
            const uploadProgress = ref(0);
            const uploadedSize = ref(0);


            function formatBytes(bytes: number) {
                if (bytes === 0) return '0 Bytes';
                const k = 1024;
                const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
                const i = Math.floor(Math.log(bytes) / Math.log(k));
                return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
            }

            function handleFileSelect(event: any) {
                const file = event.target.files[0];
                if (file && file.name.endsWith('.zip')) {
                    selectedFile.value = file;
                    selectedFileName.value = file.name;
                } else {
                    alert('Please select a zip file');
                }
            }

            function removeFileSelect() {
                selectedFile.value = null;
                selectedFileName.value = '';
            }

            async function uploadFile() {
                if (!selectedFile.value) {
                    alert('No file selected');
                    return;
                }

                try {
                    const fileName = selectedFile.value.name;

                    const projectId = project.id;

                    const tmp_multipart_upload_filenames: Record<string, number> = {};

                    // Update the filenames and parts count
                    const partCount = getPartCount(selectedFile.value.size, partSize.value);
                    const key = `${projectId}/${fileName}`;
                    tmp_multipart_upload_filenames[key] = partCount;

                    const requestdata = {
                        project_id: projectId,
                        multipart_upload_filenames: tmp_multipart_upload_filenames
                    };

                    uploadProgress.value = 2; // Start with 5% for initialization

                    // console.log('Request data:', requestdata);
                    const response = await store.dispatch('projects/uploadZipInitProcess', requestdata);
                    //console.log('Response:', response);

                    uploadProgress.value = 5; // Increase to 5% after initialization

                    if (response && response.multipart_upload_presigned_urls) {
                        updateProjectUpload({ projectId: projectId, id: response.project_upload_id, files: [], });

                        type PresignedUrlEntry = { [filename: string]: { [key: string]: string }; };

                        const presignedUrls: PresignedUrlEntry = response.multipart_upload_presigned_urls;
                        // console.log('Presigned URLs:', presignedUrls);

                        //selectedFile.value.name = response.object_id + '.zip';

                        // Create a new File object with the updated name
                        const newFileName = `${response.object_id}.zip`;
                        const newFile = new File([selectedFile.value], newFileName, { type: selectedFile.value.type });
                        selectedFile.value = newFile;



                        const filename = `${projectId}/${selectedFile.value.name}`;
                        //console.log('File Name:', filename);

                        // Find the presigned URL entry that matches the filename
                        const uploadDetails = presignedUrls[filename];
                        const upload_id = ref('');

                        let totalPartsUploaded = 0;
                        const uploadPromises = [];

                        //console.log('uploadDetails:', uploadDetails);
                        for (const [partNumberStr, presignedUrl] of Object.entries(uploadDetails)) {
                            if (partNumberStr !== 'upload_id') {
                                try {
                                    const partNumber = parseInt(partNumberStr, 10);

                                    const start = (partNumber - 1) * partSize.value;
                                    const end = Math.min(partNumber * partSize.value, selectedFile.value.size);
                                    const filePart = selectedFile.value.slice(start, end);

                                    
                                    // Create a function that returns a promise
                                    const uploadPromise = axios.put(presignedUrl, filePart, {
                                        headers: {
                                            'Content-Type': 'application/zip',
                                        },
                                    }).then(uploadResponse => {
                                        partETags.value.push({
                                            PartNumber: partNumber,
                                            ETag: uploadResponse.headers.etag.slice(1, -1)
                                        });

                                        totalPartsUploaded++;
                                        uploadedSize.value += end - start;
                                        uploadProgress.value = 5 + Math.round((totalPartsUploaded / partCount) * 90); // 5% to 95% for part uploads

                                        return uploadResponse;
                                    }).catch(uploadError => {
                                        console.error(`Error uploading part ${partNumberStr} of file ${filename}:`, uploadError);
                                        throw uploadError; // Rethrow to handle errors in Promise.all
                                    });

                                    // Add promise to the array
                                    uploadPromises.push(uploadPromise);

                                } catch (uploadError) {
                                    console.error(`Error uploading part ${partNumberStr} of file ${filename}:`, uploadError);
                                }
                            }
                            else {
                                upload_id.value = presignedUrl;
                            }
                        }

                        // Use Promise.all to wait for all uploads to complete
                        Promise.all(uploadPromises).then(async (results) => {
                            console.log('All parts uploaded successfully:', results);

                            uploadProgress.value = 100; // Set to 95% after all parts uploaded

                            // Step 3: Notify the server that all parts have been uploaded

                            await store.dispatch('projects/finishAndRun', {
                                filename: filename,
                                upload_id: upload_id.value,
                                parts: partETags.value,
                                projectUploadId: projectUpload.id,
                                projectId: project.id,
                                detail: detail.value,
                                useObjectMask: useObjectMask.value == 'true',
                                featureSensitivity: featureSensitivity.value,
                            });

                            uploadProgress.value = 100; // Set to 100% after finalization
                            router.push('projects');

                        }).catch((error) => {
                            console.error('Error in uploading one or more parts:', error);
                        });
                    }
                } catch (error) {
                    console.error('Error during file upload:', error);
                    uploadProgress.value = 0; // Reset progress in case of error
                }
            }

            useObjectMask.value = 'true';
            featureSensitivity.value = '0';
            projecttype.value = '1';
            detail.value = '2';


            function updateProjectUpload(newValues: Partial<ProjectUpload>) {
                projectUpload = { ...projectUpload, ...newValues, updated: new Date() };
            }

            const onSubmit = handleSubmit(async () => {
                //console.log('submitcalled')
                try {
                    errorMsg.value = '';
                    submitting.value = true;

                    if (basicdetail.value) {
                        let ptype = projectType.Sculpture;
                        if (projecttype.value != '1') {
                            ptype = projectType.Painting;
                        }

                        project = await store.dispatch('projects/addProject', {
                            name: name.value,
                            objectMask: true,
                            type: ptype,
                            height: projectheight.value,
                            captureMode: CaptureMode.ImportImages
                        });

                        if (project != null) {
                            basicdetail.value = false;
                            uploaddetail.value = true;
                        }
                    }
                    uploaddetail.value = true;
                    submitting.value = false;
                } catch (reason) {
                    submitting.value = false;
                    errorMsg.value =
                        'Please enter valid details. Project name should be unique.';
                }
            });


            // Function to count the number of parts required
            function getPartCount(fileSize: number, tmppartSize: number): number {
                return Math.ceil(fileSize / tmppartSize);
            }


            function numbersOnly(evt: any) {
                evt = evt || window.event;
                const charCode = evt.which ? evt.which : evt.keyCode;
                const input = evt.target.value;

                if (
                    (charCode !== 8 &&
                        charCode !== 46 &&
                        (charCode < 48 || charCode > 57)) ||
                    (charCode === 46 && (input.indexOf('.') !== -1 || input === '')) ||
                    (input.indexOf('.') !== -1 &&
                        input.split('.')[1].length >= 1 &&
                        charCode !== 8)
                ) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            }

            function showDetails() {
                showModal.value = true;
            }

            function hideDetails() {
                showModal.value = false;
            }


            return {
                name,
                nameError,
                projecttype,
                projecttypeError,
                projectheight,
                projectheightError,
                projectfile,
                projectfileError,
                useObjectMask,
                useObjectMaskError,
                detail,
                onSubmit,
                detailError,
                featureSensitivity,
                featureSensitivityError,
                errorMsg,
                submitting,
                basicdetail,
                uploaddetail,
                projectType,
                numbersOnly,
                showDetails,
                hideDetails,
                showModal,
                images,
                isModalOpen: false,
                selectedFile,
                selectedFileName,
                partSize,
                partETags,
                handleFileSelect,
                removeFileSelect,
                uploadFile,
                uploadProgress,
                formatBytes,
                uploadedSize,
                deleteButton: {
                    color: '#ff5959',
                    textDecoration: 'none',
                    gap: '4px',
                    width: '20px',
                },
            };
        },
    });