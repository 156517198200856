<template>
  <div class='choices'>
    <label class='choice__container'>
      <input
        class='choice__input'
        type='radio'
        name='choice'
        id='choice-editor'
        :value='valueS.value1'
        v-model='Vmodel'
      />
      <span class='choice__btn btn'>{{ valueS.value1 }}</span>
    </label>
    <label class='choice__container'>
      <input
        class='choice__input'
        type='radio'
        name='choice'
        id='choice-viewer'
        :value='valueS.value2'
        v-model='Vmodel'
      />
      <span class='choice__btn btn'>{{ valueS.value2 }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'RadioInputesBlock',
  props: {
    defaultValue: String,
    valueS: Object,
  },
  data: function () {
    return {
      Vmodel: this.defaultValue,
    };
  },
  watch: {
    Vmodel: function (value) {
      this.$emit('getUpdates', value);
    },
  },
};
</script>

<style scoped>
.choices {
  background-color: var(--purple5);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.57em;
  padding: 0.28em;
}
.choice__container {
  position: relative;
}
.choice__input {
  width: 0;
  position: absolute;
  cursor: pointer;
}
.choice__btn {
  width: 100%;
  letter-spacing: 0;
  color: var(--white);
  display: flex;
  justify-content: center;
  padding: 0.71em 0.2em;
}
.choice__input:checked ~ .btn {
  background-color: var(--purple);
}
</style>
