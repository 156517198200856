
import { defineComponent, ref } from 'vue';
import { useForm, useField } from 'vee-validate';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { State } from '../store';
import api from '../api';

export default defineComponent({
  setup() {
    const simpleSchema = {
      oldPassword(value: string | undefined): boolean | string {
        if (value && value.trim()) {
          return true;
        }

        return 'This is required';
      },
      newPassword(value: string | undefined): boolean | string {
        if (value && value.trim()) {
          return true;
        }

        return 'This is required';
      },
      password2(value: string | undefined): boolean | string {
        if (value === newPassword.value) {
          return true;
        }

        return 'The given passwords do not match!';
      },
    };

    const { handleSubmit } = useForm({
      validationSchema: simpleSchema,
    });
    const { value: oldPassword, errorMessage: oldPasswordError } = useField<string>('oldPassword');
    const { value: newPassword, errorMessage: newPasswordError } =
      useField<string>('newPassword');
    const { value: password2, errorMessage: passwordError2 } =
      useField<string>('password2');
    const store = useStore<State>();
    const errorMsg = ref('');
    const router = useRouter();

    const onSubmit = handleSubmit(async () => {
      try {
        await api.changePassword({
          oldPassword: oldPassword.value,
          newPassword: newPassword.value
        }, store.state.auth.jwt);
        router.push('profile');
      } catch (reason) {
        errorMsg.value = 'incorrect password';
      }
    });

    return {
      onSubmit,
      oldPassword,
      oldPasswordError,
      newPassword,
      newPasswordError,
      password2,
      passwordError2,
      errorMsg,
    };
  },
});
