<template>
    <div class="add-new-user__modal projects-users__modal projects-users__modal-side"
         v-if="startAddNewTagModal"
         @click.self="closeAddNewTagModal">
        <div class="add-new-user projects-users-modal-side-content">
            <CloseBtn @click="closeAddNewTagModal" />
            <h2>Add new tag</h2>

            <div class="projects-users__modal-input-block">
                <label class="projects-users__modal-label" for="name">Name</label>
                <input v-model="name"
                       class="text-input projects-users__modal-input"
                       type="text"
                       id="name"
                       required />
            </div>

            <div class="modal__apliqe-btns">
                <AddBtn class="modal__main-btn"
                        text="Add new tag"
                        @click="addNewTag" />
            </div>
        </div>
    </div>
</template>

<script>
    import AddBtn from '../components/AddBtn.vue';
    import CloseBtn from '../components/CloseBtn.vue';

    export default {
        name: 'AddNewTagModal',
        components: {
            AddBtn,
            CloseBtn,
        },
        props: {
            startAddNewTagModal: {
                type: Boolean,
                default: false,
            },
        },
        data: function () {
            return {
                name: ''
            };
        },
        methods: {
            addNewTag: function () {
                if (this.name) {
                    const newTag = {
                        name: this.name
                    };

                    this.$emit('addTag', newTag);

                    this.closeAddNewTagModal();

                    this.$emit('changeTagAddedNotificationState', true);

                    //close user dded Notification after a while
                    setTimeout(() => {
                        this.$emit('changeTagAddedNotificationState', false);
                    }, 4000);
                } else {
                    alert('Please fill all required fields.');
                }
            },
            closeAddNewTagModal: function () {
                this.$emit('EditAddNewTagModalExistence', false);
            },
        },
    };</script>
