<template>
  <div class="user-notification__wrapper" v-if="StartUserRemovedNotification">
    <div class="user-added-notification user-notification">
      <CloseBtn
        class="close-user-notication"
        size="s"
        @click="this.$emit('forceCloseRemovedNotification')"
      />
      <svg
        class="removed-icon"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 4.5H3.33333H14"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.33301 4.49992V3.16659C5.33301 2.81296 5.47348 2.47382 5.72353 2.22378C5.97358 1.97373 6.31272 1.83325 6.66634 1.83325H9.33301C9.68663 1.83325 10.0258 1.97373 10.2758 2.22378C10.5259 2.47382 10.6663 2.81296 10.6663 3.16659V4.49992M12.6663 4.49992V13.8333C12.6663 14.1869 12.5259 14.526 12.2758 14.7761C12.0258 15.0261 11.6866 15.1666 11.333 15.1666H4.66634C4.31272 15.1666 3.97358 15.0261 3.72353 14.7761C3.47348 14.526 3.33301 14.1869 3.33301 13.8333V4.49992H12.6663Z"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.66699 7.83325V11.8333"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.33301 7.83325V11.8333"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <p>You removed {{ removedItem }}</p>
    </div>
  </div>
</template>

<script>
import CloseBtn from '../components/CloseBtn';
export default {
  name: 'UserRemovedNotification',
  components: {
    CloseBtn,
  },
  props: {
    StartUserRemovedNotification: Boolean,
    removedItem: String,
  },
};
</script>

<style scoped>
.user-added-notification {
  background-color: var(--tomato);
  padding-right: 3.25em;
}
.removed-icon {
  width: 1.2em;
}
</style>
