

import { defineComponent, ref } from 'vue';
import { useForm, useField } from 'vee-validate';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { State } from '../store';

export default defineComponent({
  setup() {
    const simpleSchema = {
      email(value: string | undefined): boolean | string {
        if (value && value.trim()) {
          return true;
        }
        return 'This is required';
      },
      password(value: string | undefined): boolean | string {
        if (value && value.trim()) {
          return true;
        }

        return 'This is required';
      },
    };

    const { handleSubmit } = useForm({
      validationSchema: simpleSchema,
    });
    const { value: email, errorMessage: emailError } = useField('email');
    const { value: password, errorMessage: passwordError } =
      useField('password');
    const store = useStore<State>();
    const errorMsg = ref('');
    const router = useRouter();
    const submitting = ref(false);
    const onSubmit = handleSubmit(async () => {
      try {
        submitting.value = true;
        await store.dispatch('login', {
          email: email.value,
          password: password.value
        });
        submitting.value = false;
        router.push('profile');
      } catch (reason) {
        submitting.value = false;   
        errorMsg.value = 'incorrect email or password';
      }
    });

    
    async function onForgotPassword() {
      router.push('/forgotpassword')
    }
 
    async function onSuccess(data: any) {
    try {
        submitting.value = true;
        await store.dispatch('apple', {
          authorizationcode: data.authorization.code,
          username: data.userData.sub,
          fromweb: true
        });
        submitting.value = false;
        router.push('profile');
      } catch (reason) {
        submitting.value = false;
        errorMsg.value = 'incorrect apple detail';
      }
    }

    async function onSuccessLogout(){
            errorMsg.value = 'Logout';
    }

     async function onFailure(error: any) {
        let ot = '';
        for (const property in error.detail) {
          ot += property + ': ' + error.detail[property]+'; ';
        }
        errorMsg.value = 'Error' + ot;
    }
    
    return {
      onSubmit,
      onForgotPassword,
      email,
      emailError,
      password,
      passwordError,
      errorMsg,
      submitting,
    onSuccess,
    onFailure
    };
  },
});


    