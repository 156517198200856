import { ActionContext } from 'vuex';
import { findById, patchArray, setArray, setItem } from './util';
import { State } from '.';
import { Project } from '@/models/Project';
import { InitProjectUploadResponseData } from '@/models/InitProjectUploadResponseData';
import { ApiResponse } from '@/models/ApiResponse';
import { ProjectUpload } from '@/models/ProjectUpload';
import { ProjectFinishData } from '@/models/ProjectFinishData';
import { InitProjectUploadRequestData } from '@/models/InitProjectUploadRequestData';
import { ProjectCropRequestData } from '@/models/ProjectCropRequestData';
import { ProjectStatus } from '../models/ProjectStatus';
import api from '../api';


export interface ProjectsState {
    items: Array<Project>;
}

type Context = ActionContext<ProjectsState, State>;

export default {
    namespaced: true,
    state: () => ({
        items: Array<Project>()
    }),
    mutations: {
        setProjects: function (state: ProjectsState, projects: Array<Project>) {
            state.items = setArray(state.items, projects);
        },
        patchProjects: function (state: ProjectsState, projects: Array<Project>) {
            patchArray(state.items, projects);
        },
        setProject: function (state: ProjectsState, project: Project) {
            setItem(state.items, project);
        }
    },
    actions: {
        getProjects: async function (context: Context): Promise<Array<Project>> {
            const projects = await api.getProjects(context.rootState.auth.jwt);
            context.commit('setProjects', projects);
            return context.state.items;
        },
        addProject: async function (context: Context, project: Project): Promise<Project> {
            project = await api.addProject(project, context.rootState.auth.jwt);
            context.commit('setProject', project);
            return findById<Project>(context.state.items, project.id) as Project;
        },
        updateProject: async function (context: Context, project: Project): Promise<Project> {
            project = await api.updateProject(project, context.rootState.auth.jwt);
            context.commit('setProject', project);
            return findById<Project>(context.state.items, project.id) as Project;
        },
        updateProjectStatus: async function (context: Context, project: Project): Promise<Project> {
            project = await api.updateProjectStatus(project, context.rootState.auth.jwt);
            context.commit('setProject', project);
            return findById<Project>(context.state.items, project.id) as Project;
        },
        croppingProject: async function (context: Context, project: Project): Promise<Project> {
            const croppingdata: ProjectCropRequestData = {
                skipFramingProcess: true,
                isPostPurchaseEnabled: true,
                useAdjustedFile: false
            };
            await api.croppingProject(project, croppingdata, context.rootState.auth.jwt);
            project.status = ProjectStatus.Optimizing;
            context.commit('setProject', project);
            return findById<Project>(context.state.items, project.id) as Project;
        },
        deleteProject: async function (context: Context, project: Project): Promise<void> {
            context.commit('setProjects', context.state.items.filter((i: Project) => i !== project));
            await api.deleteProject(project, context.rootState.auth.jwt);
        },
        deleteAllProject: async function (context: Context): Promise<void> {
            context.commit('setProjects', context.state.items.filter((i: Project) => i.id == 0));
            await api.deleteAllProject(context.rootState.auth.jwt);
        },
        deleteSelectedProject: async function (context: Context, projectIds : string): Promise<void> {
            //context.commit('setProjects', context.state.items.filter((i: Project) => i.id == 0));
            await api.deleteSelectedProject(projectIds, context.rootState.auth.jwt);
        },

        deleteSelectedJob: async function (context: Context, jobIds: string): Promise<void> {
            //context.commit('setProjects', context.state.items.filter((i: Project) => i.id == 0));
            await api.deleteSelectedJob(jobIds, context.rootState.auth.jwt);
        },
        //projectUploads: async function (context: Context, project: Project): Promise<ProjectUpload> {
        //    return await api.projectUploads(project, context.rootState.auth.jwt);
        //},
        uploadImage: async function (context: Context, projectUpload: ProjectUpload): Promise<boolean> {
            await api.uploadImage(projectUpload, context.rootState.auth.jwt);
            return true;
        },

        //finishAndRun: async function (context: Context, requestData: ProjectFinishData): Promise<void> {
        //    return await api.finishAndRunWithBounding(requestData, context.rootState.auth.jwt);
        //},

        finishAndRun: async function (context: Context, requestData: ProjectFinishData): Promise<void> {
            try {
                const result = await api.finishAndRunWithBounding(requestData, context.rootState.auth.jwt);
                return result;
            } catch (error) {
                throw new Error('Failed to finish and run');
            }
        },

        uploadImageInitProcess: async function (context: Context, initProjectUploadRequestData: InitProjectUploadRequestData): Promise<ApiResponse<InitProjectUploadResponseData>> {
            return await api.uploadImageInitProcess(initProjectUploadRequestData, context.rootState.auth.jwt);
        },

        uploadZipInitProcess: async function (context: Context, initProjectUploadRequestData: InitProjectUploadRequestData): Promise<ApiResponse<InitProjectUploadResponseData>> {
            return await api.uploadZipInitProcess(initProjectUploadRequestData, context.rootState.auth.jwt);
        },
    }
}