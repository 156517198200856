
import { defineComponent, ref } from 'vue';
import { useForm, useField } from 'vee-validate';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { State } from '../store';
import { User } from '../models/User';
import api from '../api';

export default defineComponent({
  setup() {
    const emailVerifiicationToken = useRoute().params.emailVerificationToken as string
    const store = useStore<State>();
    const errorMsg = ref('');
    const router = useRouter();

    const { handleSubmit } = useForm({
        //validationSchema: simpleSchema,
    });


    const onSubmit = handleSubmit(async () => {
      try {
        await api.verifyemail(emailVerifiicationToken);
          errorMsg.value = 'Your email is verified!';
      } catch (reason) {
            errorMsg.value = 'Your email is not verified!';
      }
    });

    return {
      onSubmit,
      errorMsg  
    };
  },
});
