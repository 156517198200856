
import { defineComponent, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import api from '../api';

export default defineComponent({
  async setup() {
    const key = useRoute().params.urlkey as string
    const router = useRouter();
    const errorMsg = ref('');

        try {
            const url = await api.getUrlFromKey(key);
            window.location.href = String(url);
        }
        catch (reason)
        {
            errorMsg.value = 'incorrect data';
        }
    return {
      errorMsg
    };
  },
});
