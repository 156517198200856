
import { defineComponent, ref } from 'vue';
import { useForm, useField } from 'vee-validate';
import { useStore } from 'vuex';
import { State } from '../store';

export default defineComponent({
  setup() {
    const simpleSchema = {
      email(value: string | undefined): boolean | string {
        if (value && value.trim()) {
          return true;
        }

        return 'This is required';
      }
    };

    const { handleSubmit } = useForm({
      validationSchema: simpleSchema,
    });
    const { value: email, errorMessage: emailError } = useField('email');
    
    const store = useStore<State>();
    const errorMsg = ref('');
    const successMsg = ref ('');
    const submitting = ref(false);
        const onSubmit = handleSubmit(async () =>
        {
            try
            {
                submitting.value = true;
                await store.dispatch('forgotpassword',
                    {
                        email: email.value
                    })
                    .then(() =>
                    {
                         errorMsg.value = '';
                         successMsg.value = 'Please check your mail box, We sent reset password link to your email address!';
                    }).catch(error => {
                        errorMsg.value = error.response.data;
                    });
                submitting.value = false;
            }
            catch (reason)
            {
                submitting.value = false;
                errorMsg.value = 'incorrect email';
            }
        });
    return {
      onSubmit,
      email,
      emailError,
      errorMsg,
      successMsg,
      submitting
    };
  },
});
