
import { useStore } from 'vuex';
import { State } from '../store';
import { User } from '../models/User';
import { useForm, useField } from 'vee-validate';
import api from '../api';
import { JobRunner } from '../models/JobRunner';
import { defineComponent, ref, computed } from 'vue';
import AddNewUserModal from '../components/AddNewUserModal.vue';
import EditUserModal from '../components/EditUserModal.vue';
import UserAddedNotification from '../components/UserAddedNotification.vue';
import RUSureModal from '../components/RUSureModal.vue';
import UserRemovedNotification from '../components/UserRemovedNotification.vue';

export default defineComponent({
  setup() {
    const store = useStore<State>();
    const runners = ref(Array<JobRunner>());
    const expectedVersion = ref(0);

    const startAddNewUserModal = ref(false);
    const startEditUserModal = ref(false);
    const StartUserAddedNotification = ref(false);
    const startRUSureModal = ref(false);
    const StartUserRemovedNotification = ref(false);
    const userToDelete = ref<User>();
    const userToDeleteName = ref<string>();
    const userToEdit = ref<User>();

    api
      .getExpectedbRunnerVersion(store.state.auth.jwt)
      .then((version) => (expectedVersion.value = version));
    api
      .getJobRunners(store.state.auth.jwt)
      .then((data) => (runners.value = data));
    store.dispatch('users/getUsers');

    const layout = ref<'table' | 'boxes'>('table');
    const checkedStatus = ref(false);

    const setLayout = (newLayout: 'table' | 'boxes') => {
      layout.value = newLayout;
    };

    function openNewUser() {
      startAddNewUserModal.value = !startAddNewUserModal.value;
    }

    function openEditUser(user: User) {
      startEditUserModal.value = !startEditUserModal.value;
      userToEdit.value = user;
    }

    function activateEditUserModal(user: User) {
      userToEdit.value = user;
      return false;
    }

    function existEditUserModal(existOrNot: boolean) {
      startEditUserModal.value = existOrNot;
    }

    const getButtonClass = (buttonLayout: 'table' | 'boxes') => {
      return layout.value === buttonLayout
        ? 'main-btn projects-users__switch-btn btn'
        : 'second-btn projects-users__switch-btn btn';
    };

    const layoutClass = computed(() => {
      return layout.value === 'table'
        ? 'user-layout'
        : 'user-layout layout-boxes';
    });

    function displayUserName(userName: string): string {
      if (userName != null && userName.length > 15) {
        return `${userName.substring(0, 15)}..`;
      }
      return userName;
    }

    function EditAddNewUserModalExistence(value: boolean) {
      startAddNewUserModal.value = value;
    }

    function changeUserAddedNotificationState(value: boolean) {
      StartUserAddedNotification.value = value;
    }

    function existRUSureModal(state: boolean) {
      startRUSureModal.value = state;
    }

    function confirmDelete(user: User) {
      userToDelete.value = user;
      userToDeleteName.value = user.userName;
      existRUSureModal(true);
    }
    function deleteConfirmed() {
      store.dispatch('users/deleteUser', userToDelete.value);
      existRUSureModal(false);
      triggerRemovedNotification();
    }
    function triggerRemovedNotification() {
      StartUserRemovedNotification.value = true;
      setTimeout(() => {
        StartUserRemovedNotification.value = false;
      }, 4000);
    }
    function forceCloseRemovedNotification() {
      StartUserRemovedNotification.value = false;
    }

    return {
      displayUserName,
      layout,
      setLayout,
      getButtonClass,
      layoutClass,
      openNewUser,
      openEditUser,
      //layout: 'table',
      //activeLayout: 'table',
      existEditUserModal,
      userToDeleteName,
      userToDelete,
      existRUSureModal,
      startRUSureModal,
      startEditUserModal,
      userToEdit,
      confirmDelete,
      deleteConfirmed,
      triggerRemovedNotification,
      forceCloseRemovedNotification,
      StartUserRemovedNotification,
      activateEditUserModal,
      EditAddNewUserModalExistence,
      changeUserAddedNotificationState,
      startAddNewUserModal,
      StartUserAddedNotification,
      store,
      runners,
      editButton: {
        color: '#8c66ff',
        textDecoration: 'underline',
        gap: '4px',
        letterSpacing: '1px',
        text: 'Edit',
      },
      deleteButton: {
        color: '#ff5959',
        textDecoration: 'none',
        gap: '4px',
        width: '16px',
        text: 'Delete',
      },
      exportButton: {
        color: '#ffffff',
        textDecoration: 'none',
        gap: '0.75em',
        border: '1px solid var(--purple4)',
        padding: '0.775em 1.5em',
      },
    };
  },
  methods: {
    remove(user: User) {
      const question = `Do you really wish to delete ${user.userName} (${user.email})?`;
      if (confirm(question)) {
        this.store.dispatch('users/deleteUser', user);
      }
    },
    addUser: async function (newUser: User) {
      await this.store.dispatch('users/addUser', newUser);
      this.store.dispatch('users/getUsers');
    },
    updateUser: async function (editUser: User) {
      await this.store.dispatch('users/updateUser', editUser);
    },
  },
  components: {
    AddNewUserModal,
    EditUserModal,
    UserRemovedNotification,
    RUSureModal,
    UserAddedNotification,
  },
});
