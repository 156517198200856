
    import { useStore } from 'vuex';
    import { State } from '../store';
    import { Role } from '../models/Role';
    import { useForm, useField } from 'vee-validate';
    import api from '../api'
    import { defineComponent, ref, computed } from 'vue';
    //import AddNewRoleModal from '../components/AddNewRoleModal.vue';

    export default defineComponent({
        setup() {
            const store = useStore<State>();
            const expectedVersion = ref(0);
            const startAddNewRoleModal = ref(false);
            const startEditRoleModal= ref(false);
            let  StartRoleAddedNotification = false;
            const  startRUSureModal= false;
            const  StartRoleRemovedNotification=false;
            const  roleToDeleteId= false;
            
            store.dispatch('roles/getRoles');

            const layout = ref<'table' | 'boxes'>('table');
            const checkedStatus = ref(false);

            const setLayout = (newLayout: 'table' | 'boxes') => {
                layout.value = newLayout;
            };

            function openNewRole()
            {
                startAddNewRoleModal.value = !startAddNewRoleModal.value;
            }

            

            const getButtonClass = (buttonLayout: 'table' | 'boxes') => {
                return layout.value === buttonLayout
                    ? 'main-btn projects-users__switch-btn btn'
                    : 'second-btn projects-users__switch-btn btn';
            };

            const layoutClass = computed(() => {
                return layout.value === 'table' ? 'user-layout' : 'user-layout layout-boxes';
            });

            function displayRoleName(name: string): string {
                if (name.length > 15) {
                    return `${name.substring(0, 15)}..`;
                }
                return name;
            }

            function EditAddNewRoleModalExistence(value: boolean) {
                 startAddNewRoleModal.value = value;
            }

            function changeRoleAddedNotificationState(value: boolean) {
                StartRoleAddedNotification = value;
            }
            

            return {
                displayRoleName,
                layout,
                setLayout,
                getButtonClass,
                layoutClass,
                openNewRole,
                //layout: 'table',
                //activeLayout: 'table',
                EditAddNewRoleModalExistence,
                changeRoleAddedNotificationState,
                startAddNewRoleModal,
                StartRoleAddedNotification,
                store,
                editButton: {
                    color: '#8c66ff',
                    textDecoration: 'none',
                    gap: '4px',
                    letterSpacing: '1px',
                    text: 'Edit'
                },
                deleteButton: {
                    color: '#ff5959',
                    textDecoration: 'none',
                    gap: '4px',
                    width: '16px',
                    text: 'Delete'
                },
                exportButton: {
                    color: '#ffffff',
                    textDecoration: 'none',
                    gap: '0.75em',
                    border: '1px solid var(--purple4)',
                    padding: '0.775em 1.5em',
                },
            };
        },
        methods: {
            remove(role: Role) {
                const question = `Do you really wish to delete ${role.name}?`;
                if (confirm(question)) {
                    this.store.dispatch('roles/deleteRole', role);
                }
            },
            addRole: function (newRole: Role) {
              //roleRole.id = this.roles.length + 1;
              //roles.push(newRole);
            },
        },
        //components: {
        //    AddNewRoleModal
        //  },
    });
