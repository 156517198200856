
    import { reactive } from 'vue';
    import { defineComponent } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';

    export default defineComponent({
        setup() {

            const store = useStore();
            const router = useRouter();
            const editedUser = reactive({ ...store.state.auth.user });

            console.log('Edited user:', editedUser);

            const submitForm = async () => {
                try {
                    const response = await store.dispatch('updateProfile', editedUser);
                    store.commit('setUser', editedUser);
                    console.log('User updated successfully');
                    router.push('/profile');

                } catch (error) {
                    console.error('Error occurred while updating profile:', error);
                }
            };

            const goToProfilePage = () => {
                console.log('Navigating to profile page');
                router.push('/profile');
            };

            return {
                editedUser,
                submitForm,
                goToProfilePage
            };
        }
    });
