import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4fc4570a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "range-list" }
const _hoisted_2 = { class: "edit-view__input-wrapper range-list__input-wrapper" }
const _hoisted_3 = ["list", "step", "min", "max"]
const _hoisted_4 = { class: "range-list__input-bulls" }
const _hoisted_5 = {
  class: "range-list__markers",
  id: "list"
}
const _hoisted_6 = ["value", "label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        class: "range-list__input",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentValue) = $event)),
        type: "range",
        list: _ctx.list,
        step: _ctx.step,
        min: _ctx.min,
        max: _ctx.max,
        onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateNewValue && _ctx.updateNewValue(...args)))
      }, null, 40, _hoisted_3), [
        [_vModelText, _ctx.currentValue]
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.valuesAndLabels, (valueLabel, i) => {
          return (_openBlock(), _createElementBlock("span", {
            class: "range-list__input-bull",
            key: i
          }))
        }), 128))
      ])
    ]),
    _createElementVNode("datalist", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.valuesAndLabels, (valueLabel, i) => {
        return (_openBlock(), _createElementBlock("option", {
          class: "range-list__marker",
          value: valueLabel.value,
          label: valueLabel.label,
          style: _normalizeStyle(
          _ctx.name === 'editView' ? { fontSize: `${valueLabel.value}em` } : {}
        ),
          key: i
        }, null, 12, _hoisted_6))
      }), 128))
    ])
  ]))
}