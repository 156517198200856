<template>
  <div
    class="edir-user__modal projects-users__modal projects-users__modal-side"
    v-if="startEditUserModal"
    @click.self="closeEditUserModal"
  >
    <div class="add-new-user projects-users-modal-side-content">
      <CloseBtn @click="closeEditUserModal" />
      <h2>Edit user</h2>

      <div class="projects-users__modal-input-block">
        <label class="projects-users__modal-label" for="role"
          >Select Role</label
        >
        <RadioInputesBlock
          class="radio-inputes-block"
          label="Select choice"
          :valueS="{ value1: 'Editor', value2: 'Viewer' }"
          :defaultValue="editedUser.isAdmin ? 'Editor' : 'Viewer'"
          @getUpdates="updateRole"
        />
      </div>

      <div class="projects-users__modal-input-block">
        <label class="projects-users__modal-label" for="userName">Name</label>
        <input
          class="text-input projects-users__modal-input"
          type="text"
          id="userName"
          v-model="editedUser.userName"
          required
        />
      </div>

      <div class="projects-users__modal-input-block">
        <label class="projects-users__modal-label" for="email">Email</label>
        <input
          v-model="editedUser.email"
          class="text-input projects-users__modal-input"
          type="email"
          id="email"
          required
        />
      </div>

      <div class="projects-users__modal-input-block">
        <label class="projects-users__modal-label" for="credits">Credits</label>
        <input
          v-model="editedUser.credit"
          class="text-input projects-users__modal-input"
          type="number"
          id="credit"
          required
        />
      </div>

      <div class="projects-users__modal-input-block">
        <label class="projects-users__modal-label">Runner</label>
        <div
          class="projects-users-select__container projects-users__modal-input"
        >
          <select v-model="editedUser.runnerId" class="projects-users-select">
            <option
              v-for="runner in runners"
              :key="runner.id"
              :value="runner.id"
            >
              {{ runner.name }}
            </option>
          </select>

          <OpenArrow class="projects-users-select__open-arrow" />
        </div>
      </div>

      <div class="modal__apliqe-btns">
        <ApplyBtn
          class="modal__main-btn"
          text="Save changes"
          type="submit"
          @click="saveChanges"
        />
        <DeleteBtn
          class="modal__secondary-btn"
          text="Remove user"
          textDecoration="none"
          color="var(--tomato)"
          @click="confirmDelete(editedUser)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RadioInputesBlock from '../components/RadioInputesBlock.vue';
import OpenArrow from '../components/OpenArrow.vue';
import ApplyBtn from '../components/ApplyBtn';
import DeleteBtn from '../components/DeleteBtn.vue';
import CloseBtn from '../components/CloseBtn';
import { JobRunner } from '../models/JobRunner';
import { User } from '../models/User';

export default {
  name: 'EditUserModal',
  components: {
    RadioInputesBlock,
    OpenArrow,
    ApplyBtn,
    DeleteBtn,
    CloseBtn,
  },
  props: {
    startEditUserModal: {
      type: Boolean,
      default: false,
    },
    userToEdit: User,
    runners: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      editedUser: { ...this.userToEdit },
    };
  },
  watch: {
    userToEdit: {
      immediate: true,
      handler(newUser) {
        this.editedUser = { ...newUser };
      },
    },
  },
  methods: {
    updateRole: function (newRole) {
      this.editedUser.isAdmin = newRole === 'Editor' ? true : false;
    },
    saveChanges() {
      const newUser = {
        id: this.editedUser.id,
        userName: this.editedUser.userName,
        email: this.editedUser.email,
        isAdmin: this.editedUser.isAdmin,
        isActive: this.editedUser.isActive,
        credit: this.editedUser.credit,
        runnerId: this.editedUser.runnerId,
      };
      this.$emit('updateUser', newUser);

      this.$emit('updateUser', this.editedUser);
      this.closeEditUserModal();
    },
    confirmDelete: function (User) {
      this.$emit('confirmDelete', User);
      this.$emit('existEditUserModal', false);
    },
    closeEditUserModal() {
      this.$emit('existEditUserModal', false);
    },
  },
};
</script>
