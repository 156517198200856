import { ActionContext } from 'vuex';
import { findById, patchArray, setArray, setItem } from './util';
import { State } from '.';
import { ProjectTag } from '@/models/ProjectTag';
import { Tag } from '@/models/Tag';
import api from '../api';

export interface ProjectTagsState {
  items: Array<ProjectTag>;
}

type Context = ActionContext<ProjectTagsState, State>;


export default {
  namespaced: true,
  state: () => ({
    items: Array<ProjectTag>()
  }),
  mutations: {
    setProjectTags: function(state: ProjectTagsState, projectTags: Array<ProjectTag>) {
      //state.items = setArray(state.items,projectTags);
    },
    patchProjectTags: function(state: ProjectTagsState, projectTags: Array<ProjectTag>) {
      //patchArray(state.items,projectTags);
    },
    setProjectTag: function(state: ProjectTagsState, projectTag: ProjectTag) {
      //setItem(state.items, projectTag);
    }
  },
  actions: {
    addProjectTag: async function(context: Context, projectTag: ProjectTag): Promise<ProjectTag> {
      projectTag = await api.addProjectTag(projectTag, context.rootState.auth.jwt);
      context.commit('setProjectTag', projectTag);
      return projectTag;// findById<ProjectTag>(context.state.items, projectTag.tagId) as ProjectTag;
      },
    
    deleteProjectTag: async function(context: Context, projectTag: ProjectTag): Promise<void> {
      context.commit('setProjectTags', context.state.items.filter((i: ProjectTag) => i !== projectTag));
      await api.deleteProjectTag(projectTag, context.rootState.auth.jwt);
    }
  }
}
