import { ActionContext } from 'vuex';
import { findById, patchArray, setArray, setItem } from './util';
import { State } from '.';
import { User } from '@/models/User';
import api from '../api';

export interface UsersState {
  items: Array<User>;
}

type Context = ActionContext<UsersState, State>;

export default {
  namespaced: true,
  state: () => ({
    items: Array<User>(),
  }),
  mutations: {
    setUsers: function (state: UsersState, users: Array<User>) {
      state.items = setArray(state.items, users);
    },
    patchUsers: function (state: UsersState, users: Array<User>) {
      patchArray(state.items, users);
    },
    setUser: function (state: UsersState, user: User) {
      setItem(state.items, user);
    },
  },
  actions: {
    getUsers: async function (context: Context): Promise<Array<User>> {
      const users = await api.getUsers(context.rootState.auth.jwt);
      context.commit('setUsers', users);
      return context.state.items;
    },
    getUser: async function (context: Context, id: number): Promise<User> {
      const user = await api.getUser(id, context.rootState.auth.jwt);
      context.commit('setUser', user);
      return findById<User>(context.state.items, user.id) as User;
    },
    addUser: async function (context: Context, user: User): Promise<User> {
      user = await api.addUser(user, context.rootState.auth.jwt);
      context.commit('setUser', user);
      return findById<User>(context.state.items, user.id) as User;
    },
    updateUser: async function (context: Context, user: User): Promise<User> {
      user = await api.updateUser(user, context.rootState.auth.jwt);
      context.commit('setUser', user);
      return findById<User>(context.state.items, user.id) as User;
    },
    updateUserCredit: async function (
      context: Context,
      user: User
    ): Promise<User> {
      user = await api.updateUserCredit(user, context.rootState.auth.jwt);
      context.commit('setUser', user);
      return findById<User>(context.state.items, user.id) as User;
    },
    updateUserRunner: async function (
      context: Context,
      user: User
    ): Promise<User> {
      user = await api.updateUserRunner(user, context.rootState.auth.jwt);
      context.commit('setUser', user);
      return findById<User>(context.state.items, user.id) as User;
    },
    deleteUser: async function (context: Context, user: User): Promise<void> {
      context.commit(
        'setUsers',
        context.state.items.filter((i: User) => i !== user)
      );
      await api.deleteUser(user, context.rootState.auth.jwt);
    },
  },
};
