
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'RangeList',
  props: {
    defaultValue: String,
    step: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    valuesAndLabels: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
  },
  data: function () {
    return {
      currentValue: this.defaultValue,
    };
  },
  methods: {
    updateNewValue: function () {
      this.$emit('updateValue', this.currentValue);
    },
  },
});
