<template>
  <button class="apply-btn main-btn btn-has-icon btn">
    <svg
      class="apply-icon"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1668 3.5L5.75016 9.91667L2.8335 7"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    {{ text }}
  </button>
</template>

<script>
export default {
  name: 'ApplyBtn',
  props: {
    text: {
      type: String,
      default: 'Apply',
    },
  },
};
</script>

<style scoped>
.apply-icon {
  width: 1.25em;
}
</style>
