import { useStore } from 'vuex';
    import { State } from '../store';
    import { JobRunner } from '../models/JobRunner';
    import { defineComponent, ref, computed } from 'vue';
    import api from '../api'

    export default defineComponent({
        setup() {
            const store = useStore<State>();
            const runners = ref(Array<JobRunner>());
            const expectedVersion = ref(0);
            api.getExpectedbRunnerVersion(store.state.auth.jwt).then(version => expectedVersion.value = version);
            api.getJobRunners(store.state.auth.jwt).then(data => runners.value = data)
            function setActivationState(runner: JobRunner) {
                if (runner.isActive) {
                    api.activate(store.state.auth.jwt, runner);
                } else {
                    api.deactivate(store.state.auth.jwt, runner);
                }
            }

            const layout = ref<'table' | 'boxes'>('table');
            const setLayout = (newLayout: 'table' | 'boxes') => {
                layout.value = newLayout;
            };


            const getButtonClass = (buttonLayout: 'table' | 'boxes') => {
                return layout.value === buttonLayout
                    ? 'main-btn projects-users__switch-btn btn'
                    : 'second-btn projects-users__switch-btn btn';
            };

            const layoutClass = computed(() => {
                return layout.value === 'table' ? 'user-layout' : 'user-layout layout-boxes';
            });

            function updateRunnerName(runner: JobRunner) {
                api.updateRunnerName(store.state.auth.jwt, runner);
            }

            async function remove(runner: JobRunner) {
                if (confirm('Are you sure that you want to delete this job runner with all its jobs?')) {
                    await api.deleteJobRunner(runner, store.state.auth.jwt);
                    runners.value = await api.getJobRunners(store.state.auth.jwt);
                }
            }

            async function ping(runner: JobRunner) {
                await api.pingJobRunner(runner, store.state.auth.jwt);
                runners.value = await api.getJobRunners(store.state.auth.jwt);
            }
            return {
                layoutClass,
                getButtonClass,
                setLayout,
                layout,
                store,
                runners,
                setActivationState,
                updateRunnerName,
                remove,
                ping,
                expectedVersion,
                editButton: {
                    color: '#8c66ff',
                    textDecoration: 'none',
                    gap: '4px',
                    letterSpacing: '1px',
                    text: 'Edit'
                },
                deleteButton: {
                    color: '#ff5959',
                    textDecoration: 'none',
                    gap: '4px',
                    width: '16px',
                    text: 'Remove'
                },
                exportButton: {
                    color: '#ffffff',
                    textDecoration: 'none',
                    gap: '0.75em',
                    border: '1px solid var(--purple4)',
                    padding: '0.775em 1.5em',
                },
            };
        }
    });