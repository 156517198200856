
    import { useStore } from 'vuex';
    import { State } from '../store';
    import { Tag } from '../models/Tag';
    import { useForm, useField } from 'vee-validate';
    import api from '../api'
    import { defineComponent, ref, computed } from 'vue';
    import AddNewTagModal from '../components/AddNewTagModal.vue';

    export default defineComponent({
        setup() {
            const store = useStore<State>();
            const expectedVersion = ref(0);
            const startAddNewTagModal = ref(false);
            const startEditTagModal= ref(false);
            let  StartTagAddedNotification = false;
            const  startRUSureModal= false;
            const  StartTagRemovedNotification=false;
            const  tagToDeleteId= false;
            
            store.dispatch('tags/getTags');

            const layout = ref<'table' | 'boxes'>('table');
            const checkedStatus = ref(false);

            const setLayout = (newLayout: 'table' | 'boxes') => {
                layout.value = newLayout;
            };

            function openNewTag()
            {
                startAddNewTagModal.value = !startAddNewTagModal.value;
            }

            

            const getButtonClass = (buttonLayout: 'table' | 'boxes') => {
                return layout.value === buttonLayout
                    ? 'main-btn projects-users__switch-btn btn'
                    : 'second-btn projects-users__switch-btn btn';
            };

            const layoutClass = computed(() => {
                return layout.value === 'table' ? 'user-layout' : 'user-layout layout-boxes';
            });

            function displayTagName(name: string): string {
                if (name.length > 15) {
                    return `${name.substring(0, 15)}..`;
                }
                return name;
            }

            function EditAddNewTagModalExistence(value: boolean) {
                 startAddNewTagModal.value = value;
            }

            function changeTagAddedNotificationState(value: boolean) {
                StartTagAddedNotification = value;
            }
            

            return {
                displayTagName,
                layout,
                setLayout,
                getButtonClass,
                layoutClass,
                openNewTag,
                //layout: 'table',
                //activeLayout: 'table',
                EditAddNewTagModalExistence,
                changeTagAddedNotificationState,
                startAddNewTagModal,
                StartTagAddedNotification,
                store,
                editButton: {
                    color: '#8c66ff',
                    textDecoration: 'none',
                    gap: '4px',
                    letterSpacing: '1px',
                    text: 'Edit'
                },
                deleteButton: {
                    color: '#ff5959',
                    textDecoration: 'none',
                    gap: '4px',
                    width: '16px',
                    text: 'Delete'
                },
                exportButton: {
                    color: '#ffffff',
                    textDecoration: 'none',
                    gap: '0.75em',
                    border: '1px solid var(--purple4)',
                    padding: '0.775em 1.5em',
                },
            };
        },
        methods: {
            remove(tag: Tag) {
                const question = `Do you really wish to delete ${tag.name}?`;
                if (confirm(question)) {
                    this.store.dispatch('tags/deleteTag', tag);
                }
            },
            addTag: function (newTag: Tag) {
              //newTag.id = this.tags.length + 1;
              //tags.push(newTag);
              this.store.dispatch('tags/addTag', newTag);
            },
        },
        components: {
            AddNewTagModal
          },
    });
