import { createStore } from 'vuex';
import axios from 'axios';
import authModule, { AuthState } from '@/store/AuthModule';
import userModule, { UsersState } from '@/store/UserModule';
import projectModule, { ProjectsState } from '@/store/ProjectModule';
import tagModule, { TagsState } from '@/store/TagModule';
import roleModule, { RolesState } from '@/store/RoleModule';
import ProjectTagModule, { ProjectTagsState } from '@/store/ProjectTagModule';

axios.defaults.baseURL = `${window.origin}/apiv2`;

export interface State {
  auth: AuthState;
  users: UsersState;
  projects: ProjectsState;
  tags: TagsState;
  roles: RolesState;
  projectTags: ProjectTagsState;
}

export default createStore<State>({
  modules: {
    auth: authModule,
    users: userModule,
    projects: projectModule,
    tags: tagModule,
    roles: roleModule,
    projectTags: ProjectTagModule
  },
});
