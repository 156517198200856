
import { useStore } from 'vuex';
import { State } from '../store';
import { defineComponent } from 'vue';
export default defineComponent({
    setup() {
        const store = useStore<State>();
        return { store }
    },
})
