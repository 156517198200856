
import { defineComponent, ref } from 'vue';
import { useForm, useField } from 'vee-validate';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { State } from '../store';
import { User } from '../models/User';
import api from '../api';

export default defineComponent({
  setup() {
    const invitationToken = useRoute().params.invitationToken as string

    const simpleSchema = {
      password(value: string | undefined): boolean | string {
        if (value && value.trim()) {
          return true;
        }

        return 'This is required';
      },
      password2(value: string | undefined): boolean | string {
        if (value === password.value) {
          return true;
        }

        return 'The given passwords do not match!';
      },
    };

    const { handleSubmit } = useForm({
      validationSchema: simpleSchema,
    });
    const { value: password, errorMessage: passwordError } =
      useField<string>('password');
    const { value: password2, errorMessage: passwordError2 } =
      useField<string>('password2');
    const store = useStore<State>();
    const errorMsg = ref('');
    const router = useRouter();

    const onSubmit = handleSubmit(async () => {
      try {
        const user: User = await api.getInvitedUser(invitationToken);
        await api.register(invitationToken, {
          password: password.value,
        });
        await store.dispatch('login', {
          email: user.email,
          password: password.value,
        });
        router.push('/profile');
      } catch (reason) {
        errorMsg.value = 'incorrect email or password';
      }
    });

    return {
      onSubmit,
      password,
      passwordError,
      password2,
      passwordError2,
      errorMsg,
    };
  },
});
