import { createRouter, createWebHistory, RouteRecordRaw, NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import Profile from '../views/Profile.vue'
import Home from '../views/Home.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import Login from '../views/Login.vue'
import SignUp from '../views/SignUp.vue'
import VerifyEmail from '../views/VerifyEmail.vue'
import UserList from '../views/UserList.vue'
import ProjectList from '../views/ProjectList.vue'
import JobList from '../views/JobList.vue'
import Credit from '../views/Credit.vue'
import JobRunnerList from '../views/JobRunnerList.vue'
import JobActivityLog from '../views/JobActivityLog.vue'
import ChangePassword from '../views/ChangePassword.vue'
import NewProject from '../views/NewProject.vue'
import Short from '../views/Short.vue'
import store from '../store'
import ProfileUpdate from '../views/ProfileUpdate.vue';
import TagList from '../views/TagList.vue';
import RoleList from '../views/RoleList.vue';


const loggedOutGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    if (store.state.auth.user) {
        next('/home');
    } else {
        next();
    }
    checkMenu();
};
const loggedInGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    if (store.state.auth.user) {
        next();
    } else {
        next('/');
    }
    checkMenu();
};
const loggedInAppleGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    if (!store.state.auth.user?.appleUserId) {
        next();
    } else {
        next('/');
    }
    checkMenu();
};
const adminGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    if (store.state.auth.user && store.state.auth.user.isAdmin) {
        next();
    }
    next('/');
    checkMenu();
};

const openGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    next();
    checkMenu();
};

function checkMenu() {
    // Check if the toggle is open or closed
    if (document.body.classList.contains('hamburger-open')) {
        document.body.classList.toggle('hamburger-open');
    }
}

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Login',
        component: Login,
        beforeEnter: loggedOutGuard
    },
    {
        path: '/forgotpassword',
        name: 'ForgotPassword',
        component: ForgotPassword,
        beforeEnter: loggedOutGuard
    },
    {
        path: '/signup/:invitationToken',
        name: 'SignUp',
        component: SignUp,
        beforeEnter: loggedOutGuard
    },
    {
        path: '/verifyemail/:emailVerificationToken',
        name: 'VerifyEmail',
        component: VerifyEmail,
        beforeEnter: loggedOutGuard
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        beforeEnter: loggedInGuard
    },

    {
        path: '/profileupdate',
        name: 'ProfileUpdate',
        component: ProfileUpdate,
        beforeEnter: loggedInGuard
    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
        beforeEnter: loggedInGuard
    },
    {
        path: '/credit',
        name: 'Credit',
        component: Credit,
        beforeEnter: loggedInGuard
    },
    {
        path: '/change-password',
        name: 'Change Password',
        component: ChangePassword,
        beforeEnter: loggedInAppleGuard
    },
    {
        path: '/projects',
        name: 'Project List',
        component: ProjectList,
        beforeEnter: loggedInGuard
    },
    {
        path: '/jobs',
        name: 'Job List',
        component: JobList,
        beforeEnter: adminGuard
    },
    {
        path: '/log/:jobId',
        name: 'Activity Log',
        component: JobActivityLog,
        beforeEnter: loggedInGuard
    },
    {
        path: '/runners',
        name: 'Job Runner List',
        component: JobRunnerList,
        beforeEnter: adminGuard
    },
    {
        path: '/users',
        name: 'User List',
        component: UserList,
        beforeEnter: adminGuard
    },
    {
        path: '/tags',
        name: 'Tag List',
        component: TagList,
        beforeEnter: adminGuard
    },
    {
        path: '/roles',
        name: 'Role List',
        component: RoleList,
        beforeEnter: adminGuard
    },
    {
        path: '/short/:urlkey',
        name: 'Short',
        component: Short,
        beforeEnter: loggedOutGuard
    },
    {
        path: '/newproject',
        name: 'New Project',
        component: NewProject,
        beforeEnter: loggedInGuard
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
