
import { useStore } from 'vuex';
import { State } from './store';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import MainHeader from './components/MainHeader.vue';

export default defineComponent({
  components: {
    MainHeader,
  },
  setup() {
    const store = useStore<State>();
    const router = useRouter();
    return { store, router };
  },
  /*
    mounted(){
        const externalScript = document.createElement('script')
        externalScript.setAttribute('src', '/js/main.js')
        document.head.appendChild(externalScript)
    },
    updated(){
        const externalScript = document.createElement('script')
        externalScript.setAttribute('src', '/js/main.js')
        document.head.appendChild(externalScript)
    },*/
  methods: {
    logout() {
      const router = useRouter();
      localStorage.removeItem('jwt');
      this.store.commit('setUser', null);
      this.store.commit('setJwt', null);
      window.location.reload();
    },
    switchdarktheme() {
      const lightThemeCss = document.querySelector('.light-theme-css');
      lightThemeCss?.removeAttribute('href');
    },
    switchlighttheme() {
      const lightThemeCss = document.querySelector('.light-theme-css');
      lightThemeCss?.setAttribute('href', 'css/style-light.css');
    },
    hamburgerClicked() {
      document.body.classList.toggle('hamburger-open');
    },
  },
});
