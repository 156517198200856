<template>
  <div
    class="add-new-user__modal projects-users__modal projects-users__modal-side"
    v-if="startAddNewUserModal"
    @click.self="closeAddNewUserModal"
  >
    <div class="add-new-user projects-users-modal-side-content">
      <CloseBtn @click="closeAddNewUserModal" />
      <h2>Add new user</h2>

      <div class="projects-users__modal-input-block">
        <label class="projects-users__modal-label" for="role"
          >Select Role</label
        >
        <RadioInputesBlock
          class="radio-inputes-block"
          label="Select choice"
          :valueS="{ value1: 'Editor', value2: 'Viewer' }"
          :defaultValue="'Editor'"
          @getUpdates="updateRole"
        />
      </div>

      <div class="projects-users__modal-input-block">
        <label class="projects-users__modal-label" for="name">Name</label>
        <input
          v-model="name"
          class="text-input projects-users__modal-input"
          type="text"
          id="name"
          required
        />
      </div>

      <div class="projects-users__modal-input-block">
        <label class="projects-users__modal-label" for="email">Email</label>
        <input
          v-model="email"
          class="text-input projects-users__modal-input"
          type="email"
          id="email"
          required
        />
      </div>

      <div class="projects-users__modal-input-block">
        <label class="projects-users__modal-label" for="credits">Credits</label>
        <input
          v-model="credits"
          class="text-input projects-users__modal-input"
          type="number"
          id="credits"
          required
        />
      </div>

      <div class="projects-users__modal-input-block">
        <label class="projects-users__modal-label">Runner</label>
        <div
          class="projects-users-select__container projects-users__modal-input"
        >
          <select v-model="runner" class="projects-users-select">
            <option
              v-for="runner in runners"
              :key="runner.id"
              :value="runner.id"
            >
              {{ runner.name }}
            </option>
          </select>

          <OpenArrow class="projects-users-select__open-arrow" />
        </div>
      </div>

      <div class="modal__apliqe-btns">
        <AddBtn
          class="modal__main-btn"
          text="Add new user"
          @click="addNewUser"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RadioInputesBlock from '../components/RadioInputesBlock.vue';
import AddBtn from '../components/AddBtn.vue';
import OpenArrow from '../components/OpenArrow.vue';
import CloseBtn from '../components/CloseBtn.vue';
import { JobRunner } from '../models/JobRunner';

export default {
  name: 'AddNewUserModal',
  components: {
    RadioInputesBlock,
    AddBtn,
    OpenArrow,
    CloseBtn,
  },
  props: {
    startAddNewUserModal: {
      type: Boolean,
      default: false,
    },
    runners: {
      type: Array,
      default: () => [],
    },
  },
  data: function () {
    return {
      role: 'Editor',
      name: '',
      email: '',
      credits: 0,
      runner: 0,
      userProjects: '11',
    };
  },
  methods: {
    updateRole: function (newRole) {
      this.role = newRole;
    },
    addNewUser: function () {
      if (this.name && this.email && this.credits) {
        const newUser = {
          userName: this.name,
          email: this.email,
          isAdmin: this.role === 'Editor',
          isActive: true,
          credit: this.credits,
          runnerId: this.runner,
        };
        this.$emit('addUser', newUser);

        this.closeAddNewUserModal();

        this.$emit('changeUserAddedNotificationState', true);

        //close user dded Notification after a while
      } else {
        alert('Please fill all required fields.');
      }
    },
    closeAddNewUserModal: function () {
      this.$emit('EditAddNewUserModalExistence', false);
    },
  },
};
</script>
