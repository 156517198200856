import { ActionContext } from 'vuex';
import { findById, patchArray, setArray, setItem } from './util';
import { State } from '.';
import { Tag } from '@/models/Tag';
import api from '../api';

export interface TagsState {
  items: Array<Tag>;
}

type Context = ActionContext<TagsState, State>;


export default {
  namespaced: true,
  state: () => ({
    items: Array<Tag>()
  }),
  mutations: {
    setTags: function(state: TagsState, Tags: Array<Tag>) {
      state.items = setArray(state.items,Tags);
    },
    patchTags: function(state: TagsState, Tags: Array<Tag>) {
      patchArray(state.items,Tags);
    },
    setTag: function(state: TagsState, Tag: Tag) {
      setItem(state.items, Tag);
    }
  },
  actions: {
    getTags: async function(context: Context): Promise<Array<Tag>> {
      const Tags = await api.getTags(context.rootState.auth.jwt);
      context.commit('setTags', Tags);
      return context.state.items;
    },
    getTag: async function(context: Context, id: number): Promise<Tag> {
      const Tag = await api.getTag(id, context.rootState.auth.jwt);
      context.commit('setTag', Tag);
      return findById<Tag>(context.state.items, Tag.id) as Tag;
    },
    addTag: async function(context: Context, Tag: Tag): Promise<Tag> {
      Tag = await api.addTag(Tag, context.rootState.auth.jwt);
      context.commit('setTag', Tag);
      return findById<Tag>(context.state.items, Tag.id) as Tag;
    },
    updateTag: async function(context: Context, Tag: Tag): Promise<Tag> {
      Tag = await api.updateTag(Tag, context.rootState.auth.jwt);
      context.commit('setTag', Tag);
      return findById<Tag>(context.state.items, Tag.id) as Tag;
    },
    deleteTag: async function(context: Context, Tag: Tag): Promise<void> {
      context.commit('setTags', context.state.items.filter((i: Tag) => i !== Tag));
      await api.deleteTag(Tag, context.rootState.auth.jwt);
    },
  }
}
